import JSX from '../../jsx.compiler';
import {PipeType} from '../../types/enums/pipe-type.enum';

export const PAYOUTS_LAYOUT = {
	table: {
		tableColumns: [
			{
				key: '/createdOn',
				label: 'PAYOUT_DATE',
				pipe: [PipeType.Date]
			},
			{
				key: '/location',
				label: 'MERCHANT',
				populate: {
					collection: 'locations',
					displayKey: 'name',
				}
			},
			{
				key: '/paymentId',
				label: 'PAYOUT_ID'
			},
			{
				key: '/amount',
				label: 'NET_SETTLEMENT',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: (v, r) => JSX(<app-e-amount amount={v} currency={r.currency1} />)
				}
			},
			{
				key: '/amount',
				label: 'PAYMENT_CYCLE',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: (v, r) => JSX(`${r.paymentCycleStartDate}-${r.paymentCycleEndDate}`)
				}
			}
		]
	}
}