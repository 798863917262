import {PipeType} from '../../types/enums/pipe-type.enum';

export const VEHICLES_LAYOUT = {
	table: {
		tableColumns: [
			{
				key: '/customerNameLower',
				label: 'NAME',
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.customerName
				}
			},
			{
				key: '/customerMobileNumber',
				label: 'PHONE',
				sortable: true,
				pipe: [PipeType.MobileNumber],
			},
			{
				key: '/customerEmailLower',
				label: 'EMAIL_ADDRESS',
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.customerEmail
				}
			},
			{
				key: '/customerCreatedOn',
				label: 'JOIN_DATE',
				pipe: [PipeType.Date],
				disabled: true,
				sortable: true
			},
			{
				key: '/customerBirthMonth',
				label: 'BIRTH_MONTH',
				disabled: true,
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: v => {
						if (v) {
							return [
								'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
							][v - 1]
						}

						return v;
					}
				}
			},
			{
				key: '/advisorNameLower',
				label: 'ADVISOR_NAME',
				disabled: true,
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.advisorName
				}
			},
			{
				key: '/locationNameLower',
				label: 'LOCATION',
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.locationName
				}
			},
			{
				key: '/VIN',
				label: 'VIN',
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: value => value ? value.slice(9).padStart(17, '*') : '-'
				}
			},
			{
				key: '/benefitPlanNameLower',
				label: 'BENEFIT_PLAN_NAME',
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.benefitPlanName
				}
			},
			{
				key: '/benefitPlanStatus',
				label: 'BENEFIT_PLAN_STATUS',
				sortable: true,
				pipe: [PipeType.Titlecase],
				disabled: true
			},
			{
				key: '/upgradePlanNameLower',
				label: 'UPGRADE_PLAN_NAME',
				sortable: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.upgradePlanName
				}
			},
			{
				key: '/upgradePlanStatus',
				label: 'UPGRADE_PLAN_STATUS',
				pipe: [PipeType.Titlecase],
				sortable: true,
				disabled: true
			},
			{
				key: '/benefitPlanActivationDate',
				label: 'BENEFIT_PLAN_ACTIVATION_DATE',
				pipe: [PipeType.Date],
				sortable: true
			},
			{
				key: '/cancelledBenefitOn',
				label: 'CANCELATION_DATE',
				pipe: [PipeType.Date],
				sortable: true,
				disabled: true
			},
			{
				key: '/benefitPlanRenewelDate',
				label: 'PLAN_RENEWAL_DATE',
				pipe: [PipeType.Date],
				sortable: true
			},
			{
				key: '/year',
				label: 'YEAR',
				sortable: true,
				disabled: false
			},
			{
				key: '/makeLower',
				label: 'MAKE',
				sortable: true,
				disabled: false,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.make
				}
			},
			{
				key: '/modelLower',
				label: 'MODEL',
				sortable: true,
				disabled: false,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.model
				}
			},
			{
				key: '/licensePlate',
				label: 'LICENSE',
				sortable: true,
				disabled: false
			},
			{
				key: '/colorLower',
				label: 'COLOR',
				sortable: true,
				disabled: true,
				pipe: [PipeType.Custom],
				pipeArguments: {
					0: (value, row) => row.color
				}
			},
			{
				key: '/upgradePlanNextScheduleDate',
				label: 'NEXT_SCHEDULE_DATE',
				disabled: true,
				pipe: [PipeType.Date],
				sortable: true
			},
			{
				key: '/upgradePlanLastRedemptionDate',
				label: 'LAST_REDEMPTION_DATE',
				pipe: [PipeType.Date],
				sortable: true
			}
		]
	}
}