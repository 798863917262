export const PAYOUTS_SCHEMA = {
	properties: {
			amount: {type: 'number'},
			createdOn: {type: 'number'},
			currency1: {type: 'string'},
			destination1: {type: 'string'},
			destionation1: {type: 'string'},
			group: {type: 'string'},
			location: {type: 'string'},
			merchantId: {type: 'string'},
			paymentCycleEndDate: {type: 'string'},
			paymentCycleStartDate: {type: 'string'},
			paymentDate: {type: 'string'},
			paymentId: {type: 'string'},
			payoutCycle: {type: 'string'},
	},
	required: [
			'amount',
			'createdOn',
			'currency1',
			'destination1',
			'destionation1',
			'group',
			'location',
			'merchantId',
			'paymentCycleEndDate',
			'paymentCycleStartDate',
			'paymentDate',
			'paymentId',
			'payoutCycle',
	]
}